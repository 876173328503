<template>
  <div class="basket-modal-bonus">
    <loader v-if="loading" />
    <div v-if="bonuses && !loading" class="basket-modal-bonus__box">
      <div class="basket-modal-bonus__box-head">{{ $t('Кешбэк к начислению') }}</div>
      <div class="basket-modal-bonus__box-body">
        <div class="basket-modal-bonus__box-body__coin">
          <div class="coin-count">
            <span>{{ bonuses | currency }}</span>
            {{ $t('кешбэка') }}
          </div>
        </div>
        <uc-link
          class="basket-modal-bonus__box-body__link"
          :to="localePath({ name: 'info-page', params: { slug: 'bonus-rules' } })"
          >{{ $t('Узнай больше о программе МА Кешбэк ') }}
        </uc-link>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '~/src/components/page-blocks/Loader';
import { BonusesForPriceMixin } from 'u-mixins';

export default {
  name: 'BasketBonus',
  components: {
    Loader
  },
  mixins: [BonusesForPriceMixin],
  props: {
    bonusCount: {
      type: Number,
      default: 0
    },
    checkedBonus: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      bonuses: 0
    };
  },
  computed: {
    loading() {
      return this.$store.state.bonus.loading;
    }
  },
  watch: {
    bonusCount() {
      this.calculateBonuses();
    },
    checkedBonus() {
      this.calculateBonuses();
    },
    '$store.state.bonus.profile': {
      handler() {
        this.calculateBonuses();
      },
      immediate: true
    }
  },
  created() {
    this.$bonus.getBonusProfileIfNotExist();
  },
  methods: {
    calculateBonuses() {
      this.bonuses = this.getBonusAmount(this.checkedBonus, this.bonusCount);
    }
  }
};
</script>
