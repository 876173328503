<template>
  <div v-if="Array.isArray(giftItem.nomenclatureList) && giftItem.nomenclatureList.length" class="gifts-form">
    <div class="gifts-form_title">{{ giftItem.name }}</div>
    <div class="gifts-form_ico"><img src="https://static.chicco.com.ua/assets/svg/gifts/present.svg" alt="gift" /></div>
    <template v-if="!isEditing">
      <div
        v-if="giftInBasket && giftItem.isSelectable"
        class="gifts-form_item gifts-form_pointer"
        @click="setEditing(true)"
      >
        <div class="form-item_img">
          <uc-image :img="giftInBasket.currentImage" :width="`120`" :height="`120`" />
        </div>
        <div class="form-item_title">
          {{ giftInBasket.title }} <span v-if="giftInBasket.count > 1">{{ giftInBasket.count }}&nbsp;шт.</span>
        </div>
        <div v-if="giftItem.isSelectable" class="form-item__pick">
          <img src="https://static.chicco.com.ua/assets/svg/gifts/change-ico.svg" alt="pick" />{{ $t('Изменить') }}
        </div>
      </div>
      <div v-if="giftInBasket && !giftItem.isSelectable" class="gifts-form_item">
        <div class="form-item_img">
          <uc-image :img="giftInBasket.currentImage" :width="`120`" :height="`120`" />
        </div>
        <div class="form-item_title">
          {{ giftInBasket.title }} <span v-if="giftInBasket.count > 1">{{ giftInBasket.count }}&nbsp;шт.</span>
        </div>
      </div>
      <div
        v-if="!giftInBasket && giftItem.isSelectable"
        class="gifts-form_item gifts-form_pointer"
        @click="setEditing(true)"
      >
        <div class="form-item_img">
          <img src="https://static.chicco.com.ua/assets/svg/gifts/gift-for-modal.svg" alt="gift" />
        </div>
        <div class="form-item_title">{{ $t('Подарок') }}</div>
        <div class="form-item__pick">
          <img src="https://static.chicco.com.ua/assets/svg/gifts/pick-ico.svg" alt="pick" />{{ $t('Выбрать подарок') }}
        </div>
      </div>
    </template>
    <template v-if="isEditing">
      <label
        v-for="(item, key) in giftItem.nomenclatureList"
        :key="`gift-product-${key}`"
        class="gifts-form_item gifts-form_pointer"
      >
        <input v-if="giftItem.isSelectable" v-model="pickedItem" :value="item" type="radio" />
        <div class="form-item_img">
          <uc-image :img="item.images.length ? item.images[0].link : ''" :width="`120`" :height="`120`" />
        </div>
        <div class="form-item_title">
          {{ item.title }} <span v-if="item.quantity > 1">{{ item.quantity }}&nbsp;шт.</span>
        </div>
      </label>
      <div class="gifts-form_buttons">
        <uc-button class="uc-button-white-blue" @click="setEditing(false)">{{ $t('Отменить') }}</uc-button>
        <uc-button class="uc-button-blue" @click="saveItem(pickedItem, giftInBasket, giftItem.id)">{{
          $t('Сохранить')
        }}</uc-button>
      </div>
    </template>
  </div>
</template>

<script>
import SaveGiftMixin from '~/src/components/basket/mixin/SaveGift.mixin.js';

export default {
  mixins: [SaveGiftMixin],
  props: {
    giftItem: {
      type: Object,
      default: () => ({})
    },
    giftInBasket: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      pickedItem: {},
      isEditing: false
    };
  },
  watch: {
    giftItem(gift) {
      if (Array.isArray(gift.nomenclatureList) && !gift.nomenclatureList.length) {
        return null;
      }

      const giftInBasketId = (this.giftInBasket && this.giftInBasket.id) || null;
      const giftInBasketCount = (this.giftInBasket && this.giftInBasket.count) || null;
      const currentGift = gift.nomenclatureList.find((item) => item.nomenclatureId === giftInBasketId);

      if (currentGift && currentGift.quantity !== giftInBasketCount) {
        this.pickedItem = currentGift;
        this.saveGift(this.pickedItem, this.giftInBasket, this.giftItem.id);
      }
    }
  },
  mounted() {
    if (this.giftInBasket && this.giftInBasket.id) {
      this.pickedItem =
        this.giftItem.nomenclatureList.find((item) => item.nomenclatureId === this.giftInBasket.id) || {};
    }
  },
  methods: {
    setEditing(val) {
      this.isEditing = val;
    },
    saveItem(pickedItem = null, giftInBasket = null, giftItemId = null) {
      this.saveGift(pickedItem, giftInBasket, giftItemId);
      this.setEditing(false);
    }
  }
};
</script>
