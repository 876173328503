<template>
  <uc-modal v-model="dialogDisplay">
    <div class="basket-modal">
      <div class="basket-modal-head">
        <div class="basket-modal-head__title">
          <img src="https://static.chicco.com.ua/chicco-assets/svg/cart.svg" alt="Cart" />
          <span>{{ $t('Корзина') }}</span>
        </div>
      </div>
      <div class="basket-modal-list">
        <basket-modal-item
          v-for="(basketItem, index) in basketItems"
          :key="`basket-modal-list__item-${index}-${basketItem.key}`"
          :basket-item="basketItem"
        />
        <div v-if="giftLists.length" class="gifts">
          <gifts-form
            v-for="item in giftLists"
            :key="`gift-item-${item.id}`"
            :gift-item="item"
            :gift-in-basket="getGiftInBasketItem(item)"
          />
        </div>
        <basket-bonus
          v-if="isActiveBonusProgram"
          :bonus-count="totalPrice.totalBonusCount"
          :checked-bonus="totalPrice.totalCheckedBonus"
        />
      </div>
      <div class="basket-modal-actions">
        <order-to-one-click :full="isOrder" />
        <div v-if="!isOrder && !isEmptyAvailable" class="basket-modal-make-order">
          <div class="basket-modal-make-order__summ">
            <span class="total">{{ $t('Итого') }}:</span>
            <span class="summ">{{ totalPrice.totalFinalPrices | currency }}</span>
          </div>
          <uc-link :to="localePath({ name: 'order-page' })" class="uc-button uc-button-green">
            {{ $t('Оформить заказ') }}
          </uc-link>
        </div>
      </div>
      <div class="basket-modal-continue-shopping" @click="closeModal">
        <span v-if="isOrder">{{ $t('Продолжить оформление') }}</span
        ><span v-else>{{ $t('Продолжить покупки') }}</span>
      </div>
    </div>
  </uc-modal>
</template>

<script>
import BasketBonus from '~/src/components/bonus/BasketBonus.vue';
import BasketModalItem from '~/src/components/basket/BasketModalItem.vue';
import GiftsForm from '~/src/components/basket/GiftsForm.vue';
import OrderToOneClick from '~/src/components/order/blocks/OrderToOneClick.vue';
import { TranslatorMixin } from 'u-mixins';
import { basketModels } from 'u-models';
const { ITEM_TYPE_GIFT } = basketModels.basketItemType;

export default {
  components: { BasketBonus, GiftsForm, OrderToOneClick, BasketModalItem },
  mixins: [TranslatorMixin],
  data() {
    return {
      dialogDisplay: false,
      basketModel: null,
      isOrder: null,
      isActiveBonusProgram: process.env.BONUS_PROGRAM
    };
  },
  computed: {
    basketItems() {
      const basket = this.$basket.getBasket();
      const itemsWithoutGifts = basket.sortedBasketItems.filter((item) => item.itemType !== ITEM_TYPE_GIFT);
      return itemsWithoutGifts;
    },
    giftLists() {
      return this.$store.state.gift.gifts || [];
    },
    totalPrice() {
      return this.$basket.getTotalBasketPrice();
    },
    isEmptyAvailable() {
      return !this.$store.state.basket.basket || this.$store.state.basket.basket.isEmptyAvailable;
    }
  },
  watch: {
    basketItems: {
      handler(items) {
        if (!items.length) {
          this.closeModal();
        }
      },
      deep: true
    },
    '$route.path': {
      handler() {
        this.closeModal();
      }
    },
    dialogDisplay: {
      handler() {
        if (this.dialogDisplay) {
          this.$gtm.setViewBasket({ basketItems: this.basketItems });
        }
        // [BS-3529]: SalesTunnel disabled
        // if (!this.dialogDisplay) {
        //   this.$gtm.salesTunnel.deleteSalesTunnelStep(this.$gtm.salesTunnel.steps.basket);
        // }
      }
    }
  },
  created() {
    if (this.$basket) {
      this.basketModel = this.$basket.updateBasketStore();
    }
  },
  mounted() {
    this.$eventBus.$on('basket_modal:display', this.displayModal);
    this.$eventBus.$on('basket_modal:close', this.closeModal);
    this.isOrder = this.$route.name === `order-page___${this.$i18n.locale}`;
  },
  beforeDestroy() {
    this.$eventBus.$off('basket_modal:display', this.displayModal);
    this.$eventBus.$off('basket_modal:close', this.closeModal);
  },
  methods: {
    displayModal() {
      // [BS-3529]: SalesTunnel disabled
      // if (!args) {
      //   this.$gtm.salesTunnel.setSalesTunnelData(this.$store.state.basket.basket, this.$gtm.salesTunnel.steps.basket);
      // }

      this.dialogDisplay = true;
    },
    closeModal() {
      this.dialogDisplay = false;
    },
    getGiftInBasketItem(item) {
      const basket = this.$basket.getBasket();
      return basket.giftItems.find((gift) => gift.giftPromoId === item.id) || null;
    }
  }
};
</script>

<style lang="scss">
@import '~/assets/scss/modals/modal-basket.scss';
@import '~/assets/scss/gifts/gifts.scss';
</style>
