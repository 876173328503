import { AddToBasketMixin } from 'u-mixins';
import { basketModels } from 'u-models';
const GiftItemModel = basketModels.giftItem;

const GIFT_STORE_NAME = 'viewed_gift_items';

export default {
  mixins: [AddToBasketMixin],
  methods: {
    saveGift(pickedItem = null, giftInBasket = null, giftId = null) {
      if (!pickedItem) {
        return;
      }

      if (giftInBasket) {
        this.$basket.deleteGiftFromBasket(giftInBasket);
      }

      const localStore = this.$lstore.getStoreByName(GIFT_STORE_NAME) || [];

      if (!localStore.includes(giftId)) {
        localStore.push(giftId);
        this.$lstore.setStoreByName(GIFT_STORE_NAME, localStore);
      }

      const giftItemModel = new GiftItemModel(pickedItem);

      this.addGiftItemToBasket(giftItemModel, pickedItem.quantity, giftId);
    }
  }
};
