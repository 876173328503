export default {
  methods: {
    getActionMethod() {
      let action = 'order/getOrderByNumberForAnon';

      if (this.$store.state.user.auth) {
        action = 'order/getOrderByNumber';
      }

      return action;
    },
    getOneClickMethod() {
      let action = 'order/sendOrderAnonOneClick';

      if (this.$store.state.user.auth) {
        action = 'order/sendOrderUserOneClick';
      }

      return action;
    }
  }
};
