<template>
  <div v-if="totalAmount" class="order-one-click" :class="{ full: full }">
    <div v-if="loading" class="one-click__loader">
      <loader />
    </div>
    <UcForm v-if="!loading" form-class="one-click__form" :handler="submitRequest">
      <uc-phone-input v-if="visibility" v-model="phone" />
      <uc-button v-if="visibility" type="submit" class="uc-button-blue">{{ $t('Заказать') }}</uc-button>
      <div v-else class="uc-button uc-button-blue" @click="toggleVisibility">{{ $t('Заказ в 1 клик') }}</div>
    </UcForm>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { RouterMixin } from 'u-mixins';
import { orderModels } from 'u-models';

import ActionNameMixin from '~/src/components/thanks/mixin/ActionName.mixin.js';
import Loader from '~/src/components/page-blocks/Loader';
const OrderModel = orderModels.order;

export default {
  components: {
    Loader
  },
  mixins: [RouterMixin, ActionNameMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    full: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      orderModel: new OrderModel(),
      loading: false,
      visibility: false,
      phone: ''
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.data
    }),
    basketModel() {
      return this.$basket.getBasket();
    },
    totalAmount() {
      return this.basketModel.basketItems && this.basketModel.basketItems.length
        ? this.basketModel.basketItems.reduce((amount, nomenclature) => {
            return amount + nomenclature.totalAmount;
          }, 0)
        : 0;
    }
  },
  methods: {
    submitRequest() {
      this.loading = true;
      this.$store
        .dispatch(this.getOneClickMethod(), {
          phone: this.phone,
          paymentType: this.orderModel.CASH_PAYMENT_TYPE,
          items: this.orderModel.setBasket(this.basketModel).getItems() || [],
          chosenGiftItems: this.orderModel.getСhosenGiftItems()
        })
        .then(({ data }) => {
          this.loading = false;
          this.$emit('input', false);
          this.$basket.clearBasket();
          window.localStorage.setItem('order-hash', data.hash);
          this.moveToByName('thanks-order-page', { number: data.hash });
        })
        .catch((error) => {
          this.handleErrors(error);
        });
    },
    toggleVisibility() {
      this.visibility = true;
      if (this.user.phone) {
        this.phone = this.user.phone;
      }
    }
  }
};
</script>

<style lang="scss">
@import '~/assets/scss/order/order-one-click.scss';
</style>
